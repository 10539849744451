#portfolio-section {
  padding: 2em 14em;
  text-align: center;
}

#portfolio-section .portfolio-section-p {
  font-size: 23px;
}

.portfolio-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 50px;
}

.portfolio {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-image-container{
  width: 350px;
  height: 250px;
  margin: 0 auto;
}

.portfolio-image-container .portfolio-image{
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 5px 5px 10px gray;
}

.portfolio .portfolio-description {
  width: 300px;
}

.hero-container-description-first{
  margin: 0;
}

.hero-container-description-second{
  margin: 0;
}

.teammember-description{
    font-size: 25px;
    margin: 50px 0px;
}

@media only screen and (max-width: 600px) {
  #portfolio-section {
    padding: 1em 1em;
    text-align: center;
  }

  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 600px) {
  #portfolio-section {
    padding: 1em 1em;
    text-align: center;
  }

  .portfolio-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 768px) {
  #portfolio-section {
    padding: 1em 1em;
    text-align: center;
  }

  .portfolio-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  #portfolio-section {
    padding: 1em 1em;
    text-align: center;
  }

  .portfolio-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media only screen and (min-width: 1280px) {
  #portfolio-section {
    padding: 2em 1em;
    text-align: center;
  }
} */

@media only screen and (min-width: 1200px) {
  #portfolio-section {
    padding: 2em 14em;
    text-align: center;
  }

  .portfolio-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 50px;
  }
}
